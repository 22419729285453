<template>
  <div id="preloader">
    <div id="loader" class="dots-fade">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div id="top" class="s-wrap site-wrapper">
    <router-view :isAuthorized="this.isAuthorized" @reloadStickyNote="this.reloadStickyNote" />
    <SideMenu :isAuthorized="this.isAuthorized" @authentication="this.authorization" />
    <Footer />
    <StickyNote :isAuthorized="this.isAuthorized" :reloadNote="this.reloadNote" />
  </div>
</template>

<script>
import SideMenu from './components/SideMenu.vue';
import Footer from './components/Footer.vue';
import StickyNote from './components/StickyNote.vue';

export default {
  name: 'App',
  data() {
    return {
      isAuthorized: false,
      reloadNote: false
    }
  },
  components: {
    SideMenu,
    Footer,
    StickyNote
  },
  mounted() {
    this.authorization();
  },
  methods: {

    authorization: function() {
      let token = localStorage.getItem('token');
      let userInfo = localStorage.getItem('userInfo');
      if (token && userInfo) {
        let now = new Date().getTime();
        let expiredTime = JSON.parse(userInfo).expiredTime;
        this.isAuthorized = expiredTime > now;
      } else {
        this.isAuthorized = false;
      }
      if (!this.isAuthorized) {
        localStorage.removeItem('userInfo');
      }
    },

    reloadStickyNote: function() {
      this.reloadNote = !this.reloadNote;
    }

  }
}
</script>

<style></style>

<template>
  <div class="s-content">
    <div class="masonry-wrap">
      <div class="masonry">
        <div class="grid-sizer"></div>
        <article class="masonry__brick entry animate-this" v-for="data in dataList" :class="{'format-standard': data.type === 0, 'format-quote': data.type === 1}">
          <div class="entry__thumb">
            <div class="entry__thumb-link" v-if="data.type === 0" @click="postDetail(data.id, data.slug)">
              <img :src="data.thumbnail" alt="">
            </div>
            <blockquote v-if="data.type === 1">
                <p>{{ data.content }}</p>
                <cite>{{ data.title }}</cite>
            </blockquote>
          </div>
          <div class="entry__text" v-if="data.type === 0">
            <div class="entry__header">
              <h2 class="entry__title" @click="postDetail(data.id, data.slug)">
                {{ data.title }}
              </h2>
              <div class="entry__meta">
                <span class="entry__meta-cat" v-if="data.tags.length > 0">
                  <a href="#" v-for="tag in data.tags" @click="filter(null, tag.id, '', '')">
                    {{ tag.title }}
                  </a>
                </span>
                <span class="entry__meta-date">
                  <a href="#" @click="filter(null, null, data.createdDate, data.createdDate)">
                    {{ data.createdDate }}
                  </a>
                </span>
              </div>
            </div>
            <div class="entry__excerpt" @click="postDetail(data.id, data.slug)">
              <md-preview :modelValue="data.content" preview-only></md-preview>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div class="row" v-if="paging.total > 0">
      <div class="column large-full">
        <nav class="pgn">
          <ul>
            <li><a class="pgn__prev" href="#" v-if="paging.page > 1">Prev</a></li>
            <li>
              <a class="pgn__num" href="#" v-for="index in paging.pageList" :class="{'current': index === paging.page, 'dots': index === 0}" :value="index">
                {{ index !== 0 ? index : '...' }}
              </a>
            </li>
            <li><a class="pgn__next" href="#" v-if="paging.page < paging.totalPage">Next</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { MdPreview } from 'md-editor-v3';

export default {
  name: 'Home',
  components: {
    MdPreview
  },
  data() {
    return {
      dataList: [],
      images: [],
      author: null,
      tag: null,
      startDate: '',
      endDate: '',
      paging: {
        page: 1,
        pageSize: 10,
        totalPage: 1,
        pageList: [],
        total: 0
      }
    }
  },
  mounted() {
    this.$nextTick(function() {
      if (this.$route.params.tag) {
        this.tag = this.$route.params.tag;
      } else if (this.$route.query.tag) {
        this.tag = this.$route.query.tag;
      }
      this.images = this.$func.defaultImages;
      this.$func.loading(
        this.loadData,
        this.$func.loadImages,
        this.$func.ssMasonryFolio, 
        this.$func.ssBricksAnimate
      );
    });
  },
  methods: {

    loadData: async function() {
      let params = {
        params: {
          page: this.paging.page,
          pageSize: this.paging.pageSize,
          author: this.author,
          tag: this.tag,
          start: this.startDate,
          end: this.endDate
        }
      }
      let axios = this.$func.getAxios();
      await axios.get('/post', params)
        .then(response => {
          let numberOfImage = 0;
          this.dataList = response.data.data.items.map(item => {
            if (item.type == 0) {
              if (item.thumbnail == null || item.thumbnail === '') {
                let random = Math.floor(Math.random() * this.images.length)
                item.thumbnail = this.images[random].src;
              }
              if (item.tags != null) {
                item.tags.slice(0, 3);
              }
              numberOfImage++;
            }
            return item;
          });
          this.paging.page = response.data.data.page;
          this.paging.totalPage = response.data.data.totalPage;
          this.paging.total = response.data.data.total;
          this.$func.setPageList(this.paging);
        })
        .catch(error => {
          this.$func.showErrorPopUpV1(error);
        })
    },

    postDetail: function(id, slug) {
      this.$router.push({
        name: 'PostDetail',
        params: {
          slug: slug,
          postId: id
        }
      });
    },

    filter: function(author, tag, startDate, endDate) {
      this.$router.push({
        name: 'Home',
        replace: true,
        query: {
          author: author,
          tag: tag,
          startDate: startDate,
          endDate: endDate
        }
      }).then(() => {
        this.$router.go();
      });
    }

  }
}
</script>

<style scoped>
.entry__meta {
  margin: 0 !important;
}
</style>

<template>
  <div id="sticky-note" v-if="this.isAuthorized">
    <ul>
      <li v-for="data in noteDataList">
        <div :value="data.id">
          <span></span>
          <a href="#" contenteditable spellcheck="false" 
              :value="data.id" 
              @keyup="update" 
              @focusin="focusIn" 
              @focusout="focusOut">
            <p>{{ data.content }}</p>
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'StickyNote',
  data() {
    return {
      noteDataList: [],
      delayUpdating: new Map()
    }
  },
  props: [
    'isAuthorized',
    'reloadNote'
  ],
  mounted() {
    
  },
  methods: {

    getNoteList: function() {
      const axios = this.$func.getAxios();
      axios.get('/note?status=1')
        .then(response => {
          this.noteDataList = response.data.data.items.reverse();
        })
        .catch(error => {
          this.$func.showErrorPopUpV1(error);
        })
        .finally(() => {
          this.$func.makeNotesDraggable();
        });
    },

    update: function(e) {
      let character = e.keyCode >= 46 && e.keyCode <= 90;
      let backspace = e.keyCode == 8;
      let symbol = (e.keyCode >= 186 && e.keyCode <= 192) || (e.keyCode >= 219 && e.keyCode <= 222);
      if (character || backspace || symbol) {
        let noteId = e.target.getAttribute('value');
        let timeOut = this.delayUpdating.get(noteId);
        if (timeOut) {
          clearTimeout(timeOut);
        }
        this.delayUpdating.set(noteId, setTimeout(() => {
          let data = {
            id: noteId,
            content: e.target.innerText.trim()
          }
          const axios = this.$func.getAxios();
          axios.post('/note', data)
            .catch(error => {
              this.$func.showErrorPopUpV1(error);
            });
        }, 1500));
      }
    },

    focusIn: function(e) {
      let noteId = e.target.getAttribute('value');
      let container = document.querySelector('div[value=\"' + noteId + '\"]');
      container.classList.add('focus-in');
    },

    focusOut: function(e) {
      let noteId = e.target.getAttribute('value');
      let container = document.querySelector('div[value=\"' + noteId + '\"]');
      container.classList.remove('focus-in');
    }

  },
  watch: {
    isAuthorized: function(value) {
      if (value) {
        this.getNoteList();
      }
    },
    reloadNote: function(value) {
      this.getNoteList();
    }
  }
}
</script>

<style scoped>
/* https://webdesign.tutsplus.com/create-a-sticky-note-effect-in-5-easy-steps-with-css3-and-html5--net-13934t */
#sticky-note {
  font-family: 'Lato';
}

*{
  margin: 0;
  padding: 0;
}

h2 {
  font-weight: bold;
  font-size: 2rem;
}

p {
  /* font-family: 'Reenie Beanie'; */
  font-family: "Grape Nuts", serif;
  font-size: 1.8rem;
  line-height: 1.3em;
  white-space: pre;
  text-wrap: wrap;
  text-align: justify;
  word-spacing: -1px;
}

ul, li {
  list-style: none;
  position: fixed;
  top: 20px;
  right: 80px;
}

ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 2;
}

ul li div {
  transform: rotate(-6deg);
  transition: transform .15s linear;
  position: relative;
}

ul li a {
  text-decoration: none;
  color: #000;
  background: #ffc;
  display: block;
  height: 10em;
  width: 10em;
  box-shadow: 5px 5px 7px rgba(33, 33, 33, .7);
  overflow-y: scroll;
  position: relative;
  scrollbar-width: none;
  padding: 0.8em;
  cursor: text;
  outline: 0px solid transparent;
  border: 0.5px solid #ddd;
}

ul li:nth-child(even) div {
  transform: rotate(4deg);
  top: 5px;
}

ul li:nth-child(3n) div {
  transform: rotate(-3deg);
  top: -5px;
}

ul li:nth-child(5n) div {
  transform: rotate(5deg);
  top: -10px;
}

ul li:nth-child(even) a {
  background: #cfc;
}

ul li:nth-child(3n) a {
  background: #ccf;
}

ul li div:hover, .focus-in {
  transform: scale(1.25) !important;
}

ul li a:hover, ul li a:focus {
  box-shadow:10px 10px 7px rgba(0, 0, 0, .7);
  z-index: 5;
}

ul li {
  margin: 1em;
  height: 10em;
  width: 10em;
}

ul li div:hover span {
  opacity: 1;
}

ul li div span {
  z-index: 6;
  position: absolute;
  cursor: move;
  transition: all .3s;
  opacity: 0;
  font-size: 0;
  display: block;
  top: 2px;
  margin-left: 2px;
  width: 10px;
  height: 10px;
  background: linear-gradient(to bottom right, #aaa 0%, #aaa 50%, #0000 50%, #0000 100%);
}

li:has(a.note__on-top) {
  z-index: 999;
}
</style>

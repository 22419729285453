<template>
  <footer class="s-footer">
    <div class="row">
      <div class="column large-full footer__content">
        <div class="footer__copyright">
          <span>© Copyright Typerite 2019</span> 
          <span>Design by <a href="https://www.styleshout.com/" target="_blank">StyleShout</a></span>
        </div>
      </div>
    </div>
    <div class="go-top">
      <a class="smoothscroll" title="Back to Top" href="#top"></a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped></style>
